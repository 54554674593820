import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Farmer from "@/views/Farmer.vue";
import Manager from "@/views/Manager.vue";
import Home from "@/views/Home.vue";
import Instruction from "@/views/Instruction.vue";
const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/manager",
		name: "Farm Manager",
		component: Manager,
	},
	{
		path: "/farmer",
		name: "Farmer",
		component: Farmer,
	},
	{
		path: "/instruction",
		name: "Instruction",
		component: Instruction,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
