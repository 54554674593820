
import { defineComponent, onMounted, watch } from "vue";
import FarmerRewardDisplay from "@/components/gem-farm/FarmerRewardDisplay.vue";
import useWallet from "@/composables/wallet";
import useCluster from "@/composables/cluster";
import { initGemFarm } from "@/common/gem-farm";
import { PublicKey } from "@solana/web3.js";
import { parseDate } from "@/common/util";
import { useToast } from "vue-toastification";
export default defineComponent({
	components: { FarmerRewardDisplay },
	props: {
		farm: String,
		farmAcc: Object,
		farmer: String,
		farmerAcc: Object,
	},
	emits: ["refresh-farmer"],
	setup(props, ctx) {
		const toast = useToast();
		const { wallet, getWallet } = useWallet();
		const { cluster, getConnection } = useCluster();
		const farm = process.env.VUE_APP_FARM_ADDRESS;

		let gf: any;
		watch([wallet, cluster], async () => {
			gf = await initGemFarm(getConnection(), getWallet()!);
		});

		//need an onmounted hook because this component isn't yet mounted when wallet/cluster are set
		onMounted(async () => {
			if (getWallet() && getConnection()) {
				gf = await initGemFarm(getConnection(), getWallet()!);
			}
		});

		// --------------------------------------- display farmer
		// todo ideally should be using one from client, but n/a during render
		const parseFarmerState = (farmer: any): string => {
			return Object.keys(farmer.state)[0];
		};

		const refreshFarmer = async () => {
			toast.info("Refreshing Account,confirming on-chain transaction", { timeout: 60000 });
			await gf.refreshFarmerWallet(new PublicKey(farm!), new PublicKey(props.farmer!));
			toast.clear();
			toast.success("Refreshed", { timeout: 3000 });
			ctx.emit("refresh-farmer");
		};

		return {
			refreshFarmer,
			parseFarmerState,
			parseDate,
		};
	},
});
