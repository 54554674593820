
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import NavButton from "@/components/NavButton.vue";

export default defineComponent({
	components: { NavButton },
	setup() {
		const route = useRoute();
		const path = computed(() => route.path);

		const menu = [
			{ name: "/", url: "/" },

			{ name: "FARMER", url: "/farmer" },
			{ name: "Instruction", url: "/instruction" },
		];

		return {
			menu,
			path,
		};
	},
});
