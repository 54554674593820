
import { defineComponent, ref } from "vue";
export default defineComponent({
	props: {
		nft: { type: Object, required: true },
	},
	emits: ["selected"],
	setup(props, ctx) {
		const selected = ref<boolean>(false);

		const toggleSelect = () => {
			selected.value = !selected.value;
			console.log("selected", props.nft.mint.toBase58());
			ctx.emit("selected", {
				nft: props.nft,
				selected: selected.value,
			});
		};

		return {
			selected,
			toggleSelect,
		};
	},
});
