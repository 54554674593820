<template>
	<kinesis-container>
		<kinesis-element :strength="25" class="front"> </kinesis-element>
		<kinesis-element :strength="12" class="back"> </kinesis-element>
		<div class="relative h-full min-h-screen">
			<TheNavBar />
			<div class="pt-10 px-10 flex justify-center align-middle">
				<p
					class="text-4xl pt-3 px-1 mt-1 text-transparent bg-clip-text bg-gradient-to-tr from-purple-500 to-blue-200 underline"
				>
					Baby
				</p>
				<img :width="60" :height="50" src="./assets/14253.png" />
				<p
					class="text-4xl pt-3 px-2 mt-1 text-transparent bg-clip-text bg-gradient-to-tr from-blue-200 to-purple-500 underline"
				>
					Samo
				</p>
			</div>
			<div class="text-sm italic mt-5 text-center text-white-400">NFT Staking</div>

			<div class="p-10">
				<router-view />
			</div>

			<div class="pt-10"></div>
			<TheCat />
			<TheFooter />
		</div>
	</kinesis-container>
</template>

<script>
import TheNavBar from "@/components/TheNavBar";
import TheFooter from "@/components/gem-farm/TheFooter";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
export default {
	components: { TheFooter, TheNavBar, KinesisContainer, KinesisElement },
};
</script>

<style>
* {
	font-family: "Press Start 2P", monospace;

	color: rgb(255, 255, 255); /*

  background: #303030; */
}
body {
	overflow-x: hidden;
	overflow-y: scroll;
}
.front {
	position: absolute;
	background-image: url("./assets/front.png");
	background-size: cover;
	bottom: 0;
	left: -60px;

	width: 120%;
	height: 350px;
	z-index: -1;
}
.back {
	position: absolute;
	background-image: url("./assets/back.png");
	background-size: cover;
	top: 0;
	left: -20px;

	width: 120%;
	height: 100vh;
	z-index: -2;
	overflow: visible;
}
input[type="radio"]:checked + span {
	@apply text-black;
}
</style>
