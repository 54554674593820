
import { defineComponent } from "vue";
import FixedScheduleDisplay from "@/components/gem-farm/FixedScheduleDisplay.vue";
import { parseDate } from "@/common/util";
import numeral from "numeral";

export default defineComponent({
	components: { FixedScheduleDisplay },
	props: {
		reward: Object,
		title: String,
	},
	setup() {
		// todo ideally should be using one from client, but n/a during render
		const parseRewardType = (reward: any): string => {
			//returns "variable" or "fixed"
			return Object.keys(reward.rewardType)[0];
		};

		const parseRewardConfig = (reward: any) => {
			const type = parseRewardType(reward);
			if (type === "variable") {
				return reward.variableRate;
			} else {
				return reward.fixedRate;
			}
		};

		const parseRewardMint = (reward?: any) => {
			return `${reward.rewardMint.toBase58()}`;
		};

		return {
			parseRewardType,
			parseRewardConfig,
			parseRewardMint,
			parseDate,
			numeral,
		};
	},
});
