import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

createApp(App)
	.use(Toast, {
		transition: "Vue-Toastification__bounce",
		maxToasts: 20,
		newestOnTop: true,
	})
	.use(router)
	.mount("#app");
