import { readonly, ref } from "vue";
import { Commitment, Connection } from "@solana/web3.js";

export enum Cluster {
	Mainnet = "mainnet",
	Devnet = "devnet",
}
//https://ssc-dao.genesysgo.net/
//https://delicate-rough-leaf.solana-mainnet.quiknode.pro/64e5d9ef5f5ddd2419f256c282fcf629679eecc5/
//https://weathered-winter-gas.solana-mainnet.quiknode.pro/ef256cf358e09d4609f2c7f83110c2d23dd82712/
const clusterURLMapping = {
	mainnet:
		process.env.VUE_APP_MAINNET_URL ||
		"https://weathered-winter-gas.solana-mainnet.quiknode.pro/ef256cf358e09d4609f2c7f83110c2d23dd82712/",
	devnet: process.env.VUE_APP_DEVNET_URL || "https://api.devnet.solana.com",
	testnet: process.env.VUE_APP_TESTNET_URL || "https://api.testnet.solana.com",
	localnet: process.env.VUE_APP_LOCALNET_URL || "http://localhost:8899",
};
//https://delicate-rough-leaf.solana-mainnet.quiknode.pro/64e5d9ef5f5ddd2419f256c282fcf629679eecc5/
const cluster = ref<Cluster>(Cluster.Mainnet);

export default function useCluster() {
	const getClusterURL = (): string => clusterURLMapping[cluster.value];

	const getConnection = (committment?: Commitment): Connection =>
		new Connection(getClusterURL(), {
			commitment: "processed",
			confirmTransactionInitialTimeout: 60000,
		});

	const setCluster = (newCluster: Cluster) => {
		cluster.value = newCluster;
		// capping at 20 chars due to security (not to expose the token)
		console.log(
			`Cluster updated,  now ${newCluster} (${getClusterURL().substr(0, 20)})`
		);
	};

	return {
		cluster: readonly(cluster),
		getClusterURL,
		getConnection,
		setCluster,
	};
}
