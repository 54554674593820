
import { computed, defineComponent } from "vue";
import { WalletName } from "@solana/wallet-adapter-wallets";
import useCluster, { Cluster } from "@/composables/cluster";
import useWallet from "@/composables/wallet";

export default defineComponent({
	setup() {
		// cluster
		const { cluster, setCluster, getClusterURL } = useCluster();
		const chosenCluster = computed({
			get() {
				return cluster.value;
			},
			set(newVal: Cluster) {
				setCluster(newVal);
			},
		});

		// wallet
		const { getWalletName, setWallet } = useWallet();
		const chosenWallet = computed({
			get() {
				return getWalletName();
			},
			set(newVal: WalletName | null) {
				setWallet(newVal, getClusterURL());
			},
		});

		return {
			Cluster,
			chosenCluster,
			WalletName,
			chosenWallet,
		};
	},
});
