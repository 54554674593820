<template>
	<div class="footer pt-1 pb-1">
		<p class="px-3">🚀❤️ BABY SAMO Stake ❤️🚀</p>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
	@apply w-full flex justify-center align-middle bg-gray-900 text-center;
	position: absolute;
	bottom: 0px;
	height: 40px;
}
</style>
