
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		down: Boolean,
		up: Boolean,
		disabled: Boolean,
	},
	setup() {
		return {};
	},
});
