
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import useWallet from "@/composables/wallet";
import useCluster from "@/composables/cluster";
import { initGemFarm } from "@/common/gem-farm";
import { PublicKey } from "@solana/web3.js";
import ConfigPane from "@/components/ConfigPane.vue";
import FarmerDisplay from "@/components/gem-farm/FarmerDisplay.vue";
import Vault from "@/components/gem-bank/Vault.vue";
import { INFT } from "@/common/web3/NFTget";
import { findFarmerPDA, stringifyPKsAndBNs } from "@gemworks/gem-farm-ts";
import { useToast } from "vue-toastification";
export default defineComponent({
	components: { Vault, FarmerDisplay, ConfigPane },
	setup() {
		const { wallet, getWallet } = useWallet();
		const { cluster, getConnection } = useCluster();
		const toast = useToast();

		let gf: any;
		watch([wallet, cluster], async () => {
			await freshStart();
		});

		//needed in case we switch in from another window
		onMounted(async () => {
			const farmAddress = process.env.VUE_APP_FARM_ADDRESS;
			await freshStart();
		});

		// --------------------------------------- farmer details
		const farm = ref<string>();
		const farmAcc = ref<any>();

		const farmerIdentity = ref<string>();
		const farmerAcc = ref<any>();
		const farmerState = ref<string>();

		const availableA = ref<string>();
		const availableB = ref<string>();
		const farmAddress = process.env.VUE_APP_FARM_ADDRESS;
		//auto loading for when farm changes
		watch(farm, async () => {
			await freshStart();
			farm.value = process.env.VUE_APP_FARM_ADDRESS;
		});

		const updateAvailableRewards = async () => {
			availableA.value = (
				farmerAcc.value.rewardA.accruedReward.sub(farmerAcc.value.rewardA.paidOutReward) /
				10 ** 9
			).toString();
			availableB.value = farmerAcc.value.rewardB.accruedReward.sub(farmerAcc.value.rewardB.paidOutReward).toString();
		};
		//Ek3T3qdqKcDk8tSN83uAB1Ju3QLQRJBPiHWGCoMtYkuS test farm
		const fetchFarm = async () => {
			farmAcc.value = await gf.fetchFarmAcc(new PublicKey(process.env.VUE_APP_FARM_ADDRESS!));
			console.log(`farm found at ${farm.value}:`, stringifyPKsAndBNs(farmAcc.value));
		};

		const fetchFarmer = async () => {
			const [farmerPDA] = await findFarmerPDA(
				new PublicKey(process.env.VUE_APP_FARM_ADDRESS!),
				getWallet()!.publicKey!
			);
			farmerIdentity.value = getWallet()!.publicKey?.toBase58();
			farmerAcc.value = await gf.fetchFarmerAcc(farmerPDA);
			farmerState.value = gf.parseFarmerState(farmerAcc.value);
			await updateAvailableRewards();
			console.log(`farmer found at ${farmerIdentity.value}:`, stringifyPKsAndBNs(farmerAcc.value));
		};

		const freshStart = async () => {
			if (getWallet() && getConnection()) {
				gf = await initGemFarm(getConnection(), getWallet()!);
				farmerIdentity.value = getWallet()!.publicKey?.toBase58();

				//reset stuff
				farmAcc.value = undefined;
				farmerAcc.value = undefined;
				farmerState.value = undefined;
				availableA.value = undefined;
				availableB.value = undefined;

				try {
					await fetchFarm();
					await fetchFarmer();
				} catch (e) {
					console.log(`farm with PK ${process.env.VUE_APP_FARM_ADDRESS!} not found :(`);
				}
			}
		};

		const initFarmer = async () => {
			try {
				toast.info("Creating new farm", {
					timeout: 60000,
				});
				await gf.initFarmerWallet(new PublicKey(process.env.VUE_APP_FARM_ADDRESS!));
				toast.clear();
				toast.success("Farm created!", {
					timeout: 2000,
				});
				await fetchFarmer();
			} catch (e: any) {
				toast.error(e.message, {
					timeout: 6000,
				});
			}
		};

		// --------------------------------------- staking
		const beginStaking = async () => {
			try {
				toast.info("Staking,confirming on-chain transaction", {
					timeout: 60000,
				});

				const receipt = await gf.stakeWallet(new PublicKey(process.env.VUE_APP_FARM_ADDRESS!));
				console.log(receipt);
				await fetchFarmer();
				selectedNFTs.value = [];
				toast.clear();
				toast.success("Staked!", {
					timeout: 3000,
				});
			} catch (e: any) {
				toast.clear();
				toast.error(e.message, {
					timeout: 6000,
				});
			}
		};

		const endStaking = async () => {
			toast.info("Processing,confirming on-chain transaction", { timeout: 60000 });
			try {
				const res = await gf.unstakeWallet(new PublicKey(process.env.VUE_APP_FARM_ADDRESS!));
				console.log(res);
				toast.clear();
				toast.success("Success!", { timeout: 3000 });
				await fetchFarmer();
				selectedNFTs.value = [];
			} catch (e: any) {
				toast.clear();
				toast.error(e.message, { timeout: 6000 });
			}
		};

		const claim = async () => {
			toast.info("Claiming Tokens,confirming on-chain transaction", { timeout: 60000 });
			try {
				await gf.claimWallet(
					new PublicKey(process.env.VUE_APP_FARM_ADDRESS!),
					new PublicKey(farmAcc.value.rewardA.rewardMint!),
					new PublicKey(farmAcc.value.rewardB.rewardMint!)
				);
				toast.clear();
				toast.success("Claim Success!", { timeout: 3000 });
			} catch (e: any) {
				toast.clear();
				toast.error(e.message, { timeout: 6000 });
			}
			await fetchFarmer();
		};

		const handleRefreshFarmer = async () => {
			await fetchFarmer();
		};

		// --------------------------------------- adding extra gem
		const selectedNFTs = ref<INFT[]>([]);

		const handleNewSelectedNFT = (newSelectedNFTs: INFT[]) => {
			console.log(`selected ${newSelectedNFTs.length} NFTs`);
			selectedNFTs.value = newSelectedNFTs;
		};

		const addSingleGem = async (gemMint: PublicKey, gemSource: PublicKey, creator: PublicKey) => {
			await gf.flashDepositWallet(new PublicKey(process.env.VUE_APP_FARM_ADDRESS!), "1", gemMint, gemSource, creator);
			await fetchFarmer();
		};

		const addGems = async () => {
			await Promise.all(
				selectedNFTs.value.map((nft) => {
					const creator = new PublicKey(
						//todo currently simply taking the 1st creator
						(nft.onchainMetadata as any).data.creators[0].address
					);
					console.log("creator is", creator.toBase58());

					addSingleGem(nft.mint, nft.pubkey!, creator);
				})
			);
			console.log(`added another ${selectedNFTs.value.length} nft into staking vault`);
		};

		return {
			wallet,
			farm,
			farmAcc,
			farmer: farmerIdentity,
			farmerAcc,
			farmerState,
			availableA,
			availableB,
			initFarmer,
			beginStaking,
			endStaking,
			claim,
			handleRefreshFarmer,
			selectedNFTs,
			handleNewSelectedNFT,
			addGems,
			farmAddress,
		};
	},
});
